import axios from 'axios';
import { api as API_URL } from './api';

class AuthService {
  login(name, password) {
    return axios
      .post(API_URL + 'login', {
        name,
        password,
      })
      .then((response) => {
        if (response.data.user) {
          if (response.data.user.accessToken)
            localStorage.setItem('user', JSON.stringify(response.data.user));
        }

        return { status: response.data.status, message: response.data.message };
      });
  }

  register(name, email, password) {
    return axios
      .post(API_URL + 'register', {
        name,
        email,
        password,
      })
      .then((response) => {
        return { status: response.data.status, message: response.data.message };
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLoggedIn() {
    if (localStorage.getItem('user')) {
      return true;
    }
    return false;
  }
}

export default new AuthService();
