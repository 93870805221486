import React from 'react';

import { Link } from 'react-router-dom';

import AuthService from '../../Services/authService';

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
} from 'react-bootstrap';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      validation_error: '',
      email: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  submitForm(e) {
    e.preventDefault();

    AuthService.forgetPassword(this.state.email).then((response) => {
      response.status === 0
        ? this.setState({ validation_error: response.message })
        : this.props.history.push('/login');
    });
  }

  render() {
    let loginError = false;
    if (this.state.validation_error != '') {
      loginError = (
        <Alert variant="danger">{this.state.validation_error}</Alert>
      );
    }

    return (
      <Row className="align-items-center justify-content-center h-100">
        <Col xs={12}>
          <Row>
            <Col xs={12} className="text-center mb-5">
              <Image src="./kijanigrows_logo2.png" className="mb-5" />
              <h2> Dont worry </h2>
              <h5 className="text-muted">
                Enter your email and we will send you a password reset link.{' '}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div>
                {this.state.error !== '' && loginError}
                <Row>
                  <Col md={{ span: 6, offset: 4 }}>
                    <Form onSubmit={this.submitForm}>
                      <Form.Group className="login-input-group">
                        {/* <Form.Label>Email address</Form.Label> */}
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email</label>
                        {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
                      </Form.Group>
                      <Form.Group>
                        <Form.Row>
                          <Col
                            xs={{ span: 4, offset: 4 }}
                            className="text-left"
                          >
                            <Button
                              className="theme-primary-button"
                              variant="success"
                              type="submit"
                            >
                              Register
                            </Button>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      <div className="text-center">
                        <Link to="/login">Go back to login:)</Link>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Login;
