import React from 'react';
import SettingsService from '../../../Services/settingsService';

import './style.css';

import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      mapped: [],
      pins: [],
      unusedPins: [],
      selectedPin: '',
      sensors: [],
      selectedSensor: {},
      loading: true,
    };

    this.addMappingData = this.addMappingData.bind(this);
    this.deleteMappingData = this.deleteMappingData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getMappingData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDevice.name !== prevProps.selectedDevice.name) {
      this.getMappingData();
    }
  }

  getMappingData() {
    SettingsService.getMappingData(this.props.selectedDevice).then(
      (response) => {
        if (response.status === 1) {
          console.warn(response);

          this.setState({
            pins: response.data.pins,
            unusedPins: response.data.unusedPins,
            sensors: response.data.sensors,
            mapped: response.data.mapped,
            selectedPin: response.data.unusedPins[0],
            selectedSensor: response.data.sensors[0],
          });
          console.log('selected Sensor', this.state.selectedSensor);
          console.log('all Sensors', this.state.sensors);
        } else {
          this.setState({
            pins: [],
            unusedPins: [],
            sensors: [],
            mapped: [],
            selectedPin: '',
            selectedSensor: '',
          });
        }
      }
    );
  }

  addMappingData() {
    SettingsService.addMappingData(
      this.props.selectedDevice,
      this.state.selectedPin,
      this.state.selectedSensor
    ).then((response) => {
      // console.log(response);
      // let unusedPins = this.state.unusedPins.filter((value, index) => {
      //   if (value !== this.state.selectedPin) return value;
      // });
      // console.log(this.state.pins);

      let unusedPins = [];
      for (const [pin, value] of Object.entries(this.state.pins)) {
        if (!response.mapped[pin]) {
          unusedPins.push(pin);
        }
      }
      unusedPins.sort();
      this.setState({
        mapped: response.mapped,
        unusedPins: unusedPins,
        selectedSensor: this.state.sensors[0],
        selectedPin: unusedPins[0],
      });
    });
  }

  deleteMappingData(pin) {
    SettingsService.deleteMappingData(this.props.selectedDevice, pin).then(
      (response) => {
        let unusedPins = [];
        for (const [pin, value] of Object.entries(this.state.pins)) {
          if (!response.mapped[pin]) {
            unusedPins.push(pin);
          }
        }
        unusedPins.sort();
        this.setState({
          mapped: response.mapped,
          unusedPins: unusedPins,
          selectedSensor: this.state.sensors[0],
          selectedPin: unusedPins[0],
        });
      }
    );
  }

  handleChange(e) {
    e.target.name === 'pinSelect' &&
      this.setState({
        selectedPin: this.state.unusedPins[e.target.selectedIndex - 1],
      });
    e.target.name === 'sensorSelect' &&
      this.setState({
        selectedSensor: this.state.sensors[e.target.selectedIndex - 1],
      });
    console.log('selected Sensor', this.state.selectedSensor);
    console.log('all Sensors', this.state.sensors);
  }

  render() {
    let mappedComponent = [];
    let key = 0;
    for (const [index, value] of Object.entries(this.state.mapped)) {
      key++;
      mappedComponent.push(
        <Row className="alt-table-row" key={key}>
          <Col>{index}</Col>
          <Col>{value.sensor}</Col>
          <Col>
            <Button
              size="sm"
              variant="light"
              onClick={() => this.deleteMappingData(index)}
            >
              Delete Mapping
            </Button>
          </Col>
        </Row>
      );
    }

    let unusedPinsComponent = this.state.unusedPins.map((pin, index) => {
      return (
        <option value={pin} key={index}>
          {pin}
        </option>
      );
    });

    let sensorsComponent = this.state.sensors.map((sensor, index) => {
      return (
        <option value={sensor.sensor} key={index}>
          {sensor.sensor}
        </option>
      );
    });

    return (
      <Container fluid className="container-main mt-4">
        <h3 className="container-header"> {this.props.selectedDevice.name} </h3>
        <Container fluid className="no-padding group text-center">
          <div className="alt-table-header">
            <Row className="alt-table-heading">
              <Col>Pin</Col>
              <Col>Sensor</Col>
              <Col>Action</Col>
            </Row>
          </div>
          <div className="alt-table-body bordered body-background">
            {mappedComponent}
          </div>
          <div className="alt-table-footer">
            <Row>
              <Col>
                <Form.Control
                  className="form-select"
                  size="sm"
                  as="select"
                  name="pinSelect"
                  value={this.state.selectedPin ?? ''}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select Pin
                  </option>
                  {unusedPinsComponent}
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  className="form-select"
                  size="sm"
                  as="select"
                  name="sensorSelect"
                  value={this.state.selectedSensor.sensor ?? ''}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select Sensor
                  </option>
                  {sensorsComponent}
                </Form.Control>
              </Col>
              <Col>
                <Button
                  size="sm"
                  variant="success"
                  onClick={this.addMappingData}
                >
                  Add Mapping
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    );
  }
}

export default Settings;
