import React from 'react';

import './style.css';

import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

class NoDevice extends React.Component {
  render() {
    return (
      <Container fluid className="container-main mt-4">
        <Container fluid className="p-5 group text-center">
          <h3 className="container-header"> No Device Selected </h3>
          <Button> Add Device </Button>
        </Container>
      </Container>
    );
  }
}

export default NoDevice;
