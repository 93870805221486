import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import './style.css';

import CategoriesService from '../../../Services/categoriesService';
import SensorsService from '../../../Services/sensorsSerivce';

import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';

class CategoryShow extends React.Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      loading: true,
      selectedSensor: {},
      newGroup: '',
      groups: [],
      unsensors: [],
      sensors: [],
    };

    this.getCategory = this.getCategory.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.addSensor = this.addSensor.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.getCategory();

    SensorsService.index(
      this.props.selectedDevice,
      this.props.match.params.id
    ).then((response) => {
      console.log('get unsensors data', response);
      this.setState({
        selectedSensor: response.unsensors[0] ?? { pin: '', sensor: '' },
        unsensors: response.unsensors,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDevice.name !== prevProps.selectedDevice.name) {
      this.setState({
        redirect: true,
      });
      this.getCategory();
    }
  }

  handleChange(e) {
    e.target.name === 'newGroup' &&
      this.setState({
        newGroup: e.target.value,
      });
    e.target.name === 'sensorSelect' &&
      this.setState({
        selectedSensor: this.state.unsensors[e.target.selectedIndex],
      });
  }

  getCategory() {
    CategoriesService.show(
      this.props.selectedDevice,
      this.props.match.params.id
    ).then((response) => {
      console.log('get mapping data', response);
      this.setState({
        sensors: response.sensors,
        groups: response.groups,
      });
    });
  }

  addGroup() {
    if (this.state.newGroup != '') {
      CategoriesService.addGroup(
        this.props.selectedDevice,
        this.props.match.params.id,
        this.state.newGroup
      ).then((response) => {
        this.setState({
          newGroup: '',
          groups: response.groups,
        });
      });
    } else {
      console.error('Cannot add unnamed group');
    }
  }

  addSensor() {
    console.log(this.state.selectedSensor);
    if (this.state.selectedSensor.pin) {
      CategoriesService.addSensor(
        this.props.selectedDevice,
        this.props.match.params.id,
        this.state.selectedSensor
      ).then((response) => {
        this.setState({
          selectedSensor: '',
          sensors: response.sensors,
          unsensors: response.unsensors,
        });
      });
    } else {
      console.error('Cannot add inidentified sensor');
    }
  }

  submit(e) {
    // console.log(e.keyCode);
    if (e.keyCode == 13) {
      e.target.name === 'newGroup' && this.addGroup();
      e.target.name === 'addSensor' && this.addSensor();
    }
  }

  render() {
    if (this.state.redirect) return <Redirect exact to="/categories" />;

    let groupsComponent = this.state.groups.map((group, index) => {
      return (
        <Col lg="4" key={index}>
          <Card className="mb-2">
            <Card.Header>{group.icon}</Card.Header>
            <Card.Body>
              <Card.Title> {group.title} </Card.Title>
              <Card.Text>Some Values.</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to={`../groups/${group._id}`}> Explore </Link>
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    let sensorsComponent = this.state.sensors.map((sensor, index) => {
      return (
        <Col lg="4" key={index}>
          <Card className="mb-2">
            <Card.Header>{sensor.icon}</Card.Header>
            <Card.Body>
              <Card.Title> {sensor.pin} </Card.Title>
              <Card.Text>Some Values.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      );
    });

    let unsensorsComponent = this.state.unsensors.map((unsensor, index) => {
      return (
        <option value={unsensor.pin} key={index}>
          {unsensor.sensor}
        </option>
      );
    });

    return (
      <Container fluid className="container-main mt-4">
        <Row>
          <Col lg="6">
            <h3>Sensors</h3>
            <Form.Row className="align-items-center">
              <Col xs="auto">
                <Form.Label htmlFor="inlineFormInput" srOnly>
                  Name
                </Form.Label>
                <Form.Control
                  className="form-select"
                  size="sm"
                  as="select"
                  name="sensorSelect"
                  value={this.state.selectedSensor.pin ?? ''}
                  onChange={this.handleChange}
                >
                  <option value="" disabled>
                    Select Sensor
                  </option>
                  {unsensorsComponent}
                </Form.Control>
              </Col>
              <Col xs="auto">
                <Button type="button" className="mb-2" onClick={this.addSensor}>
                  Add
                </Button>
              </Col>
            </Form.Row>
            <Row>
              {sensorsComponent.length > 0
                ? sensorsComponent
                : 'No Sensors to Show'}
            </Row>
          </Col>
          <Col lg="6">
            <h3>Groups</h3>
            <Form.Row className="align-items-center">
              <Col xs="auto">
                <Form.Label htmlFor="inlineFormInput" srOnly>
                  Name
                </Form.Label>
                <Form.Control
                  className="mb-2"
                  id="inlineFormInput"
                  name="newGroup"
                  placeholder="Group Name"
                  onChange={this.handleChange}
                  value={this.state.newGroup}
                  onKeyDown={this.submit}
                />
              </Col>
              <Col xs="auto">
                <Button type="button" className="mb-2" onClick={this.addGroup}>
                  Add
                </Button>
              </Col>
            </Form.Row>
            <Row>
              {groupsComponent.length > 0
                ? groupsComponent
                : 'No Groups to Show'}
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CategoryShow;
