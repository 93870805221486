import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import CategoriesService from '../../../Services/categoriesService';

import './style.css';

import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';

class Categories extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      categories: [],
      newCategory: '',
    };

    this.getCategories = this.getCategories.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitCategory = this.submitCategory.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDevice.name !== prevProps.selectedDevice.name) {
      this.getCategories();
    }
  }

  componentDidMount() {
    this.getCategories();
  }

  handleChange(e) {
    e.target.name === 'newCategory' &&
      this.setState({
        newCategory: e.target.value,
      });
  }

  getCategories() {
    CategoriesService.index(this.props.selectedDevice).then((response) => {
      console.log('categories index', response.categories);
      this.setState({
        categories: response.categories,
      });
    });
  }

  addCategory() {
    if (this.state.newCategory != '') {
      CategoriesService.store(
        this.props.selectedDevice,
        this.state.newCategory
      ).then((response) => {
        this.setState({
          newCategory: '',
          categories: response.categories,
        });
      });
    } else {
      console.error('Cannot add unnamed category');
    }
  }

  submitCategory(e) {
    // console.log(e.keyCode);
    if (e.keyCode == 13) this.addCategory();
  }

  render() {
    let categoriesComponent = this.state.categories.map((category, index) => {
      return (
        <Col lg="4" key={index}>
          <Card className="mb-2">
            <Card.Header>{category.icon}</Card.Header>
            <Card.Body>
              <Card.Title> {category.title} </Card.Title>
              <Card.Text>Some Values.</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to={`categories/${category._id}`}> Explore </Link>
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    return (
      <Container fluid className="container-main mt-4">
        <Form.Row className="align-items-center">
          <Col xs="auto">
            <Form.Label htmlFor="inlineFormInput" srOnly>
              Name
            </Form.Label>
            <Form.Control
              className="mb-2"
              id="inlineFormInput"
              name="newCategory"
              placeholder="Category Name"
              onChange={this.handleChange}
              value={this.state.newCategory}
              onKeyDown={this.submitCategory}
            />
          </Col>
          <Col xs="auto">
            <Button type="button" className="mb-2" onClick={this.addCategory}>
              Add
            </Button>
          </Col>
        </Form.Row>
        <h3 className="container-header"> {this.props.selectedDevice.name} </h3>
        <Container fluid className="no-padding group text-center">
          <Row>
            {categoriesComponent.length > 0
              ? categoriesComponent
              : 'No Categories to Show'}
          </Row>
        </Container>
      </Container>
    );
  }
}

export default Categories;
