import React from 'react';
import axios from 'axios';

import SensorsService from '../../../Services/sensorsSerivce';

import './style.css';

import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';

class Sensors extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      unsensors: [],
      sensors: [],
    };

    this.getSensors = this.getSensors.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDevice.name !== prevProps.selectedDevice.name) {
      this.getSensors();
    }
  }

  componentDidMount() {
    this.getSensors();
  }

  getSensors() {
    SensorsService.index(this.props.selectedDevice).then((response) => {
      console.log('sensors index', response.sensors);
      if (response.status == 1) {
        this.setState({
          unsensors: response.unsensors,
          sensors: response.sensors,
        });
      }
    });
  }

  render() {
    let unsensorsComponent = this.state.unsensors.map((sensor, index) => {
      return (
        <Col lg="4" key={index}>
          <Card className="mb-2">
            <Card.Header>{sensor.type}</Card.Header>
            <Card.Body>
              <Card.Title> {sensor.sensor} </Card.Title>
              <Card.Text>Some Values.</Card.Text>
            </Card.Body>
            <Card.Footer>
              {/* <Link to={`sensors/${category._id}`}> Explore </Link> */}
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    let sensorsComponent = this.state.sensors.map((sensor, index) => {
      return (
        <Col lg="4" key={index}>
          <Card className="mb-2">
            <Card.Header>{sensor.type}</Card.Header>
            <Card.Body>
              <Card.Title> {sensor.sensor} </Card.Title>
              <Card.Text>Some Values.</Card.Text>
            </Card.Body>
            <Card.Footer>
              {/* <Link to={`sensors/${category._id}`}> Explore </Link> */}
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    console.log('Sensor index props', this.props);
    return (
      <Container fluid className="container-main mt-4">
        <h3 className="container-header"> 9th Street </h3>
        <Container fluid className="no-padding group">
          <h4> Assigned </h4>
          <Row>
            <Col>
              <Row>
                {sensorsComponent.length > 0
                  ? sensorsComponent
                  : 'No Sensors to Show'}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid className="no-padding group">
          <h4> Unassigned </h4>
          <Row>
            <Col>
              <Row>
                {unsensorsComponent.length > 0
                  ? unsensorsComponent
                  : 'No Sensors to Show'}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default Sensors;
