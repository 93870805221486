import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthService from '../../Services/authService';
import DevicesService from '../../Services/devicesService';
import NoDevice from '../../Components/Auth/Errors/noDevice.js';
import Error404 from '../../Components/Auth/Errors/error404.js';

import LayoutNavbar from './partials/navbar';
import LayoutSidebar from './partials/sidebar';

import { Container, Row, Col } from 'react-bootstrap';

import './style.css';

// const userLayout = ({ children }) => (
//   <div>
//     <div className="d-flex" id="wrapper">
//       <LayoutSidebar />

//       <div id="page-content-wrapper">
//         <LayoutNavbar />

//         <div className="container-fluid">
//           {/* <h3 className="mt-4">9th Street</h3> */}
//           {children}
//         </div>
//       </div>
//     </div>
//   </div>
// );

class UserLayout extends Component {
  constructor() {
    super();
    this.state = {
      sidebarState: 1,
      redirect: false,
      loading: true,
      error: false,
      loggedIn: AuthService.isLoggedIn(),
      devices: [],
      selectedDevice: { name: '', mac: '' },
    };

    this.sidebarToggle = this.sidebarToggle.bind(this);
    this.logout = this.logout.bind(this);
    this.getDevices = this.getDevices.bind(this);
    this.deviceSelect = this.deviceSelect.bind(this);
  }

  componentDidMount() {
    this.getDevices();
  }

  getDevices() {
    // this.setState({ loading: false, error: true });
    DevicesService.index().then((response) => {
      if (response.status === 1) {
        let devices = [];
        if (response.devices.length === 0) {
          this.setState({
            selectedDevice: {
              name: null,
              mac: null,
            },
          });
        } else {
          response.devices.map((device, index) => {
            if (this.state.selectedDevice.mac === '')
              this.setState({
                selectedDevice: {
                  name: device.name,
                  mac: device.mac,
                },
              });
            devices.push({
              name: device.name,
              mac: device.mac,
            });
          });
        }

        this.setState({ devices: devices, loading: false }, () => {});
      } else {
        this.setState({ error: true, loading: false });
      }
    });
  }

  deviceSelect(e) {
    this.setState({
      selectedDevice: {
        name: e.target[e.target.selectedIndex].text,
        mac: e.target.value,
      },
    });
    console.log(this.state.selectedDevice);
  }

  sidebarToggle() {
    this.setState({
      sidebarState: !this.state.sidebarState,
    });
  }

  logout() {
    AuthService.logout();
    this.setState({ loggedIn: false });
    this.props.history.push('/login');
  }
  render() {
    let Main = this.props.component;
    if (this.state.selectedDevice.mac == null) {
      Main = NoDevice;
    }
    if (this.state.error) {
      Main = Error404;
    }
    if (!this.state.loading)
      return (
        <div>
          <div
            className={
              this.state.sidebarState == 1 ? 'd-flex' : 'd-flex toggled'
            }
            id="wrapper"
          >
            <LayoutSidebar sidebarToggle={this.sidebarToggle} />

            <div id="page-content-wrapper">
              <LayoutNavbar
                // sidebarToggle={this.sidebarToggle}
                logout={this.logout}
                devices={this.state.devices}
                deviceSelect={this.deviceSelect}
                selectedDevice={this.state.selectedDevice}
              />

              <div className="container-fluid">
                {/* <h3 className="mt-4">{this.state.selectedDevice.name}</h3> */}
                <Main
                  {...this.props}
                  selectedDevice={this.state.selectedDevice}
                />
              </div>
            </div>
          </div>
        </div>
      );
    else return <div> </div>;
  }
}

const UserLayoutRoute = ({ component: Component, ...rest }) => {
  if (AuthService.isLoggedIn()) {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <UserLayout {...matchProps} component={Component}>
            {/* <Component {...matchProps} /> */}
          </UserLayout>
        )}
      />
    );
  }

  return <Redirect exact from="/sensors" to="/login" />;
};

export default UserLayoutRoute;
