import axios from 'axios';
import { api as API_URL } from './api';

class GroupsService {
  index(device) {
    return axios
      .get(`${API_URL}groups/${device.mac}`)
      .then((response) => {
        console.warn('groups service', response.data);
        let groups = [];
        for (const [name, value] of Object.entries(response.data.groups)) {
          groups.push(value);
        }

        return { groups: groups };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  store(device, group, name) {
    return axios
      .post(`${API_URL}groups`, {
        device: device.mac,
        name: name,
        group: group,
      })
      .then((response) => {
        console.log('add group api', response);
        let groups = [];
        for (const [name, value] of Object.entries(response.data.groups)) {
          groups.push(value);
        }
        return { groups: groups };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  show(device, group) {
    return axios
      .get(`${API_URL}groups/${device.mac}/${group}`)
      .then((response) => {
        console.log('get group api', response);
        console.log('get group api', response.data);
        return {
          sensors: response.data.sensors,
          groups: response.data.groups,
        };
        // return { groups: groups };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  addGroup(device, group, name) {
    return axios
      .patch(`${API_URL}groups/${device.mac}/${group}`, {
        name: name,
        _method: 'addGroup',
      })
      .then((response) => {
        console.log('add group api', response);
        let groups = [];
        for (const [name, value] of Object.entries(response.data.groups)) {
          groups.push(value);
        }
        return { groups: groups };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  addSensor(device, group, sensor) {
    return axios
      .patch(`${API_URL}groups/${device.mac}/${group}`, {
        name: sensor.pin,
      })
      .then((response) => {
        console.log('add sensor api', response);
        let sensors = [];
        for (const [name, value] of Object.entries(response.data.sensors)) {
          sensors.push(value);
        }
        let unsensors = [];
        for (const [name, value] of Object.entries(response.data.unsensors)) {
          unsensors.push(value);
        }
        return { sensors: sensors, unsensors: unsensors };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }
}

export default new GroupsService();
