import axios from "axios";
import { api as API_URL } from "./api";

class LocationsService {
  getLocations(device) {}

  addLocation(device, name) {
    return axios
      .post(`${API_URL}locations`, {
        device: device.name,
        name: name,
      })
      .then((response) => {
        console.log("add location api", response);
        let locations = [];
        for (const [name, value] of Object.entries(response.data.locations)) {
          locations.push(value);
        }
        return { locations: locations };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  getMappingData(device) {
    return axios
      .get(`${API_URL}locations/mappings/${device.name}`)
      .then((response) => {
        let sensors = [];
        for (const [pin, value] of Object.entries(response.data.sensors)) {
          sensors.push(value);
        }
        let unusedSensors = sensors;
        let locations = [];
        for (const [name, value] of Object.entries(response.data.locations)) {
          locations.push(value);
          unusedSensors.forEach((value2, index) => {
            let pins = value.sensors;
            if (pins.includes(value2.pin)) {
              unusedSensors.splice(index, 1);
            }
          });
        }
        return {
          locations: locations,
          sensors: sensors,
          unusedSensors: unusedSensors,
        };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  addMappingData(device, sensor, location) {
    console.warn("add mapping location", device, sensor, location);
    return axios
      .post(`${API_URL}locations/mappings`, {
        device: device.name,
        pin: sensor.pin,
        location: location.title,
      })
      .then((response) => {
        console.warn("add mapping location", response.data);
        // return response.data;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  deleteMappingData(device, pin) {}
}

export default new LocationsService();
