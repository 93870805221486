import React from 'react';

import { Link } from 'react-router-dom';

import AuthService from '../../Services/authService';

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
} from 'react-bootstrap';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      name: '',
      password: '',
      error: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  submitForm(e) {
    e.preventDefault();

    AuthService.login(this.state.name, this.state.password).then((response) => {
      response.status === 0
        ? this.setState({ error: response.message })
        : this.props.history.push('/sensors');
    });
  }

  render() {
    let loginError = <Alert variant="danger">{this.state.error}</Alert>;
    return (
      <Row className="align-items-center justify-content-center h-100">
        <Col xs={12}>
          <Row>
            <Col xs={12} className="text-center mb-5">
              <Image src="./kijanigrows_logo.png" className="mb-5" />
              <h2> Welcome back!</h2>
              <h5 className="text-muted"> Please login to continue. </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div>
                {this.state.error !== '' && loginError}
                <Row>
                  <Col md={{ span: 6, offset: 4 }}>
                    <Form onSubmit={this.submitForm}>
                      <Form.Group className="login-input-group">
                        {/* <Form.Label>Email address</Form.Label> */}
                        <input
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Name</label>
                        {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
                      </Form.Group>

                      <Form.Group className="login-input-group">
                        {/* <Form.Label>Password</Form.Label> */}
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Password</label>
                      </Form.Group>
                      <Form.Group id="formGridCheckbox">
                        <div className="text-right">
                          <Link to="/forgotPassword"> Forgot Password ? </Link>
                        </div>
                        <Form.Check type="checkbox" label="Remember me." />
                      </Form.Group>
                      <Form.Group>
                        <Form.Row>
                          <Col xs={5} className="text-left">
                            <Button
                              className="theme-secondary-outline-button"
                              variant="success"
                              type="button"
                              as={Link}
                              to="/register"
                            >
                              SignUp
                            </Button>
                          </Col>
                          <Col xs={4} className="text-right">
                            <Button
                              className="theme-primary-button"
                              variant="success"
                              type="submit"
                            >
                              Login
                            </Button>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Login;
