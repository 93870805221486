import React from "react";
import axios from "axios";

import "./style.css";

import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

class Prefrences extends React.Component {
  render() {
    return (
      <Container fluid className="container-main mt-4">
        <h3 className="container-header"> 9th Street </h3>
        <Container fluid className="no-padding group">
          <Container className="mt-4">
            <Col>
              <Form>
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>FACILITY</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Facility Name
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
                <hr />
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>PERSONAL INFORMATION</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      First Name
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="1">
                      Email
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Last Name
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
                <hr />
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>CONTACT INFORMATION</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Street Address
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="1">
                      State
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Zipcode
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="1">
                      Country
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
                <hr />
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>GOOGLE MAP</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Google Map Keys
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="1">
                      Longitude
                    </Form.Label>
                    <Col sm="2">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="2">
                      Laititude
                    </Form.Label>
                    <Col sm="2">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
                <hr />
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>TWITTER INFORMATION</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Twitter Notif keys
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="1">
                      Notify
                    </Form.Label>
                    <Col sm="2">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="2">
                      Twitter Name
                    </Form.Label>
                    <Col sm="2">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
                <hr />
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>CONSUMER INFORMATION</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Consume Key
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="2">
                      Consumer Secret
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
                <hr />
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>ACCESS INFORMATION</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Access Key
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                    <Form.Label column sm="2">
                      Access Secret
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <h5>
                      <b>URL INFORMATION</b>
                    </h5>
                  </Form.Label>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Short URL
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Form.Group>
                </Form.Group>
              </Form>
            </Col>
          </Container>
        </Container>
      </Container>
    );
  }
}

export default Prefrences;
