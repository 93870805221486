import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthService from '../../Services/authService';

import LayoutNav from './partials/navbar';

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
} from 'react-bootstrap';

import './style.css';

const LoginLayout = ({ children }) => (
  <Container fluid className="vh100">
    {/* <LayoutNav /> */}
    <Row className="h-100">
      <Col
        xs={6}
        className="auth-layout-left"
        style={{ background: `url('./kijanigrows_left.png')` }}
      ></Col>
      <Col xs={6} className="auth-layout-right">
        <Container className="h-85">{children}</Container>
      </Col>
    </Row>
  </Container>
);

const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  if (AuthService.isLoggedIn()) {
    return <Redirect exact from="/login" to="/sensors" />;
  }
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LoginLayout>
          <Component {...matchProps} />
        </LoginLayout>
      )}
    />
  );
};

export default LoginLayoutRoute;
