import axios from 'axios';
import { api as API_URL } from './api';

class DevicesService {
  index() {
    return axios
      .get(API_URL + 'devices/')
      .then((response) => {
        // handle success
        console.log(response.data);
        return {
          status: response.data.status,
          devices: response.data.data,
        };
      })
      .catch((error) => {
        // handle error

        console.log(error);
        return {
          status: 0,
        };
      });
  }

  create(details) {
    return axios
      .post(API_URL + 'devices/', {
        name: details.name,
        fname: details.fname,
        lname: details.lname,
        mac: details.mac,
        email: details.email,
        street: details.street,
        zipcode: details.zipcode,
        country: details.country,
        latitude: details.latitude,
        blurb: details.blurb,
        visualization: details.visualization,
        url: details.url,
        video: details.video,
        twitterName: details.twitterName,
        twitterNotify: details.twitterNotify,
        consumerKey: details.consumerKey,
        consumerSecret: details.consumerSecret,
        accessKey: details.accessKey,
        accessSecret: details.accessSecret,
        shortUrl: details.shortUrl,
      })
      .then((response) => {
        // handle success
        console.log(response.data);
        return {
          status: response.data.status,
          device: response.data.device,
        };
      })
      .catch((error) => {
        // handle error

        console.log(error);
        return {
          status: 0,
        };
      });
  }
}

export default new DevicesService();
