import React from "react";
import axios from "axios";

import "./style.css";

import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

class Product extends React.Component {
  render() {
    return (
      <Container fluid className="container-main mt-4">
        <h3 className="container-header"> 9th Street </h3>
        <Button size="sm" variant="success" className="mb-2">
          Add Bucket
        </Button>
        <Container fluid className="no-padding group text-center">
          <div className="alt-table-header">
            <Row className="alt-table-heading">
              <Col>Pin</Col>
              <Col>Sensor</Col>
              <Col>Action</Col>
            </Row>
          </div>
          <div className="alt-table-body">
            <Row className="alt-table-row">
              <Col>
                <Form.Control className="form-select" size="sm" as="select">
                  <option>Humidity</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Col>
              <Col>
                <Form.Control className="form-select" size="sm" as="select">
                  <option>ground_plant1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </Form.Control>
              </Col>
              <Col>
                <Button size="sm" variant="success">
                  Add Mapping
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    );
  }
}

export default Product;
