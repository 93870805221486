import axios from 'axios';
import { api } from './api';

class SettingsService {
  getMappingData(device) {
    console.warn(device);
    return axios
      .get(`${api}mappings/${device.mac}`)
      .then((response) => {
        // handle success
        console.log('mappings api', response);
        let unusedPins = [];
        for (const [pin, value] of Object.entries(
          response.data.deviceSensor.report.pins
        )) {
          if (!response.data.deviceSensor.device.sensors[pin]) {
            unusedPins.push(pin);
          }
        }
        unusedPins.sort();
        let res = {
          status: response.data.status,
          data: {
            pins: response.data.deviceSensor.report.pins,
            unusedPins: unusedPins,
            sensors: response.data.deviceSensor.sensors,
            mapped: response.data.deviceSensor.device.sensors,
          },
        };

        console.warn('mappings service response: ', res);
        return res;
      })
      .catch((error) => {
        // handle error
        return {
          status: 0,
        };
        console.log(error);
      });
  }

  addMappingData(device, pin, sensor) {
    // console.log(device, pin, sensor);
    // console.log(device.name, pin, sensor.sensor);
    return axios
      .post(`${api}mappings`, {
        device: device.mac,
        pin: pin,
        sensor: sensor.sensor,
      })
      .then((response) => {
        // console.warn(response.data);
        return response.data;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  deleteMappingData(device, pin) {
    // console.log(device, pin);
    // console.log(device.name, pin, sensor.sensor);
    return axios
      .delete(`${api}mappings`, {
        data: {
          device: device.mac,
          pin: pin,
        },
      })
      .then((response) => {
        // console.warn(response.data);
        return response.data;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }
}

export default new SettingsService();
