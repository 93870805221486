import React from "react";
import LocationsService from "../../../Services/locationsService";

import "./style.css";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

class Locations extends React.Component {
  constructor() {
    super();
    this.state = {
      locations: [],
      selectedLocation: {},
      newLocation: "",
      sensors: [],
      selectedSensor: {},
      unusedSensors: [],
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.addLocation = this.addLocation.bind(this);
    this.getMappingData = this.getMappingData.bind(this);
    this.addMappingData = this.addMappingData.bind(this);
    this.submitLocation = this.submitLocation.bind(this);
  }

  componentDidMount() {
    this.getMappingData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDevice.name !== prevProps.selectedDevice.name) {
      this.getMappingData();
    }
  }

  handleChange(e) {
    e.target.name === "newLocation"
      ? this.setState({
          newLocation: e.target.value,
        })
      : e.target.name === "sensorSelect"
      ? this.setState({
          selectedSensor: this.state.unusedSensors[e.target.selectedIndex],
        })
      : e.target.name === "locationSelect" &&
        this.setState({
          selectedLocation: this.state.locations[e.target.selectedIndex],
        });
  }

  submitLocation(e) {
    console.log(e.keyCode);
    if (e.keyCode == 13) this.addLocation();
  }
  getMappingData() {
    LocationsService.getMappingData(this.props.selectedDevice).then(
      (response) => {
        // console.log("mapping locations api", response);

        this.setState({
          locations: response.locations,
          selectedLocation: response.locations[0],
          sensors: response.sensors,
          unusedSensors: response.unusedSensors,
          selectedSensor: response.unusedSensors[0],
        });
      }
    );
  }

  addLocation() {
    LocationsService.addLocation(
      this.props.selectedDevice,
      this.state.newLocation
    ).then((response) => {
      this.setState({
        newLocation: "",
        locations: response.locations,
      });
    });
  }

  addMappingData() {
    LocationsService.addMappingData(
      this.props.selectedDevice,
      this.state.selectedSensor,
      this.state.selectedLocation
    ).then((response) => {});
  }

  render() {
    //   let pinsList = [];
    //   for (const [pin] of Object.entries(response.data.sensors)) {
    //     pinsList.push(pin);
    //   }
    //   let LocationsList = [];
    //   for (const [name, value] of Object.entries(response.data.sensors)) {
    //     LocationsList.push(name);
    //   }

    //   return ({pins:})
    // })

    // let mappedComponent = [];
    // let key = 0;
    // for (const [index, value] of Object.entries(this.state.mapped)) {
    //   key++;
    //   mappedComponent.push(
    //     <Row className="alt-table-row" key={key}>
    //       <Col>{index}</Col>
    //       <Col>{value.sensor}</Col>
    //       <Col>
    //         <Button
    //           size="sm"
    //           variant="light"
    //           onClick={() => this.deleteMappingData(index)}
    //         >
    //           Delete Mapping
    //         </Button>
    //       </Col>
    //     </Row>
    //   );
    // }

    let unusedSensorsComponent = this.state.unusedSensors.map(
      (sensor, index) => {
        return (
          <option value={sensor.pin} key={index}>
            {sensor.sensor}
          </option>
        );
      }
    );

    let locationsComponent = this.state.locations.map((location, index) => {
      return (
        <option value={location.title} key={index}>
          {location.title}
        </option>
      );
    });
    return (
      <Container fluid className="container-main mt-4">
        <Form.Row className="align-items-center">
          <Col xs="auto">
            <Form.Label htmlFor="inlineFormInput" srOnly>
              Name
            </Form.Label>
            <Form.Control
              className="mb-2"
              id="inlineFormInput"
              name="newLocation"
              placeholder="Location Name"
              onChange={this.handleChange}
              value={this.state.newLocation}
              onKeyDown={this.submitLocation}
            />
          </Col>
          <Col xs="auto">
            <Button type="button" className="mb-2" onClick={this.addLocation}>
              Add
            </Button>
          </Col>
        </Form.Row>
        <h3 className="container-header"> {this.props.selectedDevice.name} </h3>
        <Container fluid className="no-padding group text-center">
          <div className="alt-table-header">
            <Row className="alt-table-heading">
              <Col>Sensor</Col>
              <Col>Location</Col>
              <Col>Action</Col>
            </Row>
          </div>
          <div className="alt-table-body bordered body-background">Mapped</div>
          <div className="alt-table-footer">
            <Row>
              <Col>
                <Form.Control
                  className="form-select"
                  size="sm"
                  as="select"
                  name="sensorSelect"
                  onChange={this.handleChange}
                  value={this.state.selectedSensor.pin}
                >
                  {unusedSensorsComponent}
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  className="form-select"
                  size="sm"
                  as="select"
                  name="locationSelect"
                  onChange={this.handleChange}
                  value={this.state.selectedLocation.title}
                >
                  {locationsComponent}
                </Form.Control>
              </Col>
              <Col>
                <Button
                  size="sm"
                  variant="success"
                  onClick={this.addMappingData}
                >
                  Add Mapping
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    );
  }
}

export default Locations;
