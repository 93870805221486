import React from "react";
import axios from "axios";

import "./style.css";

import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

class Configuration extends React.Component {
  render() {
    return (
      <Container fluid className="container-main mt-4">
        <h3 className="container-header"> 9th Street </h3>
        <Container fluid className="no-padding">
          <Row>
            <Col md="2" className="group text-center">
              <div className="mt-3">
                <p>Hello</p>
                <p>Hello</p>
                <p>Hello</p>
                <p>Hello</p>
                <p>Hello</p>
              </div>
            </Col>
            <Col md="1"></Col>
            <Col md="6" className="group no-padding text-center">
              <div className="alt-table-header">
                <Row>
                  <Col>Pantilt</Col>
                </Row>
              </div>
              <div>
                <Form>
                  <Row className="mt-3">
                    <Form.Label column sm={{ span: 4, offset: 2 }}>
                      pantilt~enabled
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Form.Label column sm={{ span: 4, offset: 2 }}>
                      pantilt~delay
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-4">
                    <Form.Label column sm={{ span: 4, offset: 2 }}>
                      pantilt~plant1
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control type="text" defaultValue="" />
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default Configuration;
