import React from 'react';

import { Link } from 'react-router-dom';

import AuthService from '../../Services/authService';

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
} from 'react-bootstrap';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      loading: false,
      redirect: false,
      validation_error: false,
      name: '',
      email: '',
      password: '',
      confirm_password: '',
      agreement: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  submitForm(e) {
    e.preventDefault();
    if (this.state.password !== this.state.confirm_password) {
      this.setState({ validation_error: 'Password Confirmation Failed' });
    } else {
      AuthService.register(
        this.state.name,
        this.state.email,
        this.state.password
      ).then((response) => {
        response.status === 0
          ? this.setState({ validation_error: response.message })
          : this.props.history.push('/login');
      });
    }
  }

  render() {
    let loginError = false;
    if (this.state.validation_error != '') {
      loginError = (
        <Alert variant="danger">{this.state.validation_error}</Alert>
      );
    }

    return (
      <Row className="align-items-center justify-content-center h-100">
        <Col xs={12}>
          <Row>
            <Col xs={12} className="text-center mb-5">
              <Image src="./kijanigrows_logo.png" className="mb-5" />
              <h2> Hello there!</h2>
              <h5 className="text-muted"> Please fill in form to register. </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div>
                {this.state.validation_error && loginError}
                <Row>
                  <Col md={{ span: 6, offset: 4 }}>
                    <Form onSubmit={this.submitForm}>
                      <Form.Group className="login-input-group">
                        {/* <Form.Label>Email address</Form.Label> */}
                        <input
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Name</label>
                        {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
                      </Form.Group>

                      <Form.Group className="login-input-group">
                        {/* <Form.Label>Password</Form.Label> */}
                        <input
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Email</label>
                      </Form.Group>

                      <Form.Group className="login-input-group">
                        {/* <Form.Label>Password</Form.Label> */}
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Password</label>
                      </Form.Group>

                      <Form.Group className="login-input-group">
                        {/* <Form.Label>Password</Form.Label> */}
                        <input
                          type="password"
                          name="confirm_password"
                          value={this.state.confirm_password}
                          onChange={this.handleChange}
                          required="required"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Confirm Password</label>
                      </Form.Group>

                      <Form.Group id="formGridCheckbox">
                        <Form.Check
                          type="checkbox"
                          name="agreement"
                          label="I agree with terms and conditions."
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Row>
                          <Col
                            xs={{ span: 4, offset: 4 }}
                            className="text-left"
                          >
                            <Button
                              className="theme-secondary-button"
                              variant="success"
                              type="submit"
                            >
                              Register
                            </Button>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                      <div className="text-center">
                        <Link to="/login">
                          Already have an account? Sign in
                        </Link>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Login;
