import React from "react";
import axios from "axios";

import "./style.css";

import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

class Commands extends React.Component {
  render() {
    return (
      <Container fluid className="container-main mt-4">
        <h3 className="container-header text-center"> 9th Street Commands </h3>
        <Container fluid className="no-padding group text-center">
          <div className="alt-table-header">
            <Row>
              <Col>No.</Col>
              <Col>Date</Col>
              <Col>Command</Col>
              <Col>Action</Col>
            </Row>
          </div>
          <div className="alt-table-body">
            <Row>
              <Col>1</Col>
              <Col>Fri Oct 16 2020 21:45:39 GMT+0500</Col>
              <Col className="text-danger">pumpoff</Col>
              <Col>
                <Button size="sm" variant="light">
                  Delete Entry
                </Button>
              </Col>
            </Row>
          </div>
          <div className="alt-table-footer">
            <Row>
              <Col>1</Col>
              <Col>Fri Oct 16 2020 21:45:39 GMT+0500</Col>
              <Col className="text-success">pumpoff</Col>
              <Col>
                <Button size="sm" variant="light">
                  Delete Entry
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    );
  }
}

export default Commands;
