import React from 'react';

import './style.css';

import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';

class Error404 extends React.Component {
  render() {
    return (
      <Container fluid className="container-main mt-4">
        <Container fluid className="p-5 group text-center">
          <h3 className="container-header"> Something went wrong </h3>
          {/* <Button> Add Device </Button> */}
        </Container>
      </Container>
    );
  }
}

export default Error404;
