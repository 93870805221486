import axios from 'axios';
import { api as API_URL } from './api';

class CategoriesService {
  index(device) {
    return axios
      .get(`${API_URL}categories/${device.mac}`)
      .then((response) => {
        console.warn('categories service', response.data);
        let categories = [];
        for (const [name, value] of Object.entries(response.data.categories)) {
          categories.push(value);
        }

        return { status: response.data.status, categories: categories };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  store(device, name) {
    return axios
      .post(`${API_URL}categories`, {
        device: device.mac,
        name: name,
      })
      .then((response) => {
        console.log('add category api', response);
        let categories = [];
        for (const [name, value] of Object.entries(response.data.categories)) {
          categories.push(value);
        }
        return { categories: categories };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  show(device, category) {
    return axios
      .get(`${API_URL}categories/${device.mac}/${category}`)
      .then((response) => {
        console.log('get category api', response);
        console.log('get category api', response.data);
        return {
          sensors: response.data.sensors,
          groups: response.data.groups,
        };
        // return { categories: categories };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  addGroup(device, category, name) {
    return axios
      .patch(`${API_URL}categories/${device.mac}/${category}`, {
        name: name,
        _method: 'addGroup',
      })
      .then((response) => {
        console.log('add group api', response);
        let groups = [];
        for (const [name, value] of Object.entries(response.data.groups)) {
          groups.push(value);
        }
        return { groups: groups };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }

  addSensor(device, category, sensor) {
    return axios
      .patch(`${API_URL}categories/${device.mac}/${category}`, {
        name: sensor.pin,
        _method: 'addSensor',
      })
      .then((response) => {
        console.log('add sensor api', response);
        let sensors = [];
        for (const [name, value] of Object.entries(response.data.sensors)) {
          sensors.push(value);
        }
        let unsensors = [];
        for (const [name, value] of Object.entries(response.data.unsensors)) {
          unsensors.push(value);
        }
        return { sensors: sensors, unsensors: unsensors };
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }
}

export default new CategoriesService();
