import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import AuthService from './Services/authService';

import LoginLayoutRoute from './Layouts/loginLayout';
import UserLayoutRoute from './Layouts/userLayout';

import Home from './Components/Home';
import Register from './Components/Register';
import Login from './Components/Login';
import ForgotPassword from './Components/Login/forgotPassword';
import Sensors from './Components/Auth/Sensors';
import Trends from './Components/Auth/Trends';
import Animation from './Components/Auth/Animation';
import Settings from './Components/Auth/Settings';
import Locations from './Components/Auth/Locations';
import Product from './Components/Auth/Product';
import Categories from './Components/Auth/Categories';
import CategoryShow from './Components/Auth/Categories/show';
import Groups from './Components/Auth/Groups';
import Preferences from './Components/Auth/Preferences';
import Configuration from './Components/Auth/Configuration';
import Commands from './Components/Auth/Commands';
import CreateDevice from './Components/Auth/Devices/create';

function Routing() {
  return (
    <div>
      <Switch>
        <UserLayoutRoute
          exact
          path="/devices/create"
          component={CreateDevice}
        />
        <UserLayoutRoute exact path="/sensors" component={Sensors} />
        <UserLayoutRoute exact path="/trends" component={Trends} />
        <UserLayoutRoute exact path="/animation" component={Animation} />
        <UserLayoutRoute exact path="/settings" component={Settings} />
        <UserLayoutRoute exact path="/locations" component={Locations} />
        <UserLayoutRoute exact path="/product" component={Product} />
        <UserLayoutRoute exact path="/preferences" component={Preferences} />
        <UserLayoutRoute
          exact
          path="/configuration"
          component={Configuration}
        />
        <UserLayoutRoute exact path="/categories" component={Categories} />
        <UserLayoutRoute
          exact
          path="/categories/:id"
          component={CategoryShow}
        />
        <UserLayoutRoute exact path="/groups/:id" component={Groups} />
        <UserLayoutRoute exact path="/commands" component={Commands} />

        <LoginLayoutRoute exact path="/" component={Home} />
        <LoginLayoutRoute exact path="/login" component={Login} />
        <LoginLayoutRoute exact path="/register" component={Register} />
        <LoginLayoutRoute
          exact
          path="/forgotPassword"
          component={ForgotPassword}
        />
      </Switch>
    </div>
  );
}

export default Routing;
