import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Nav, Form, Col, Button, Image, Dropdown } from 'react-bootstrap';

class LayoutSidebar extends React.Component {
  render() {
    return (
      <>
        {/* <Nav
          className="col-md-2 d-none d-md-block layout-sidebar"
          activeKey="/home"
          onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        >
          <div className="sidebar-sticky"></div>
          <div className="sidebar-main">
            <Nav.Item>
              <Nav.Link activeClassName="active" as={NavLink} to="/home">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link activeClassName="active" as={NavLink} eventKey="link-1">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link activeClassName="active" as={NavLink} eventKey="link-2">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link activeClassName="active" as={NavLink} eventKey="disabled" disabled>
                Disabled
              </Nav.Link>
            </Nav.Item>
          </div>
        </Nav> */}
        <div className="layoutSidebar" id="sidebar-wrapper">
          <div className="sidebar-heading">
            <Button
              className="logo-button"
              onClick={this.props.sidebarToggle}
              variant="link"
            >
              <Image src="./assets/icons/logo.png"></Image>
            </Button>
            <Button
              id="alt-logo-button"
              variant="link"
              onClick={this.props.sidebarToggle}
            >
              <Image src="./assets/icons/mini-logo.png"></Image>
            </Button>
          </div>
          <div className="command-section">
            <Form.Row>
              <Col md={{ span: 10, offset: 1 }}>
                <Form.Control as="select" id="command-menu">
                  <option>1</option>
                  <option>2</option>
                </Form.Control>
              </Col>
              <Col md={{ span: 10, offset: 2 }}>
                <Button
                  id="command-button"
                  size="md"
                  variant="success"
                  className="mt-2 mb-4"
                >
                  Execute Command
                </Button>
                <Button
                  id="alt-command-button"
                  size="md"
                  variant="success"
                  className="mt-2 mb-4"
                >
                  C
                </Button>
              </Col>
            </Form.Row>
          </div>
          <div className="menu-section">
            <div className="list-group list-group-flush">
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/sensors"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/sensors.svg"></Image>{' '}
                <span> Sensors </span>
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/trends"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/trends.svg"></Image>{' '}
                <span> Trends </span>
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/animation"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/animation.svg"></Image>{' '}
                <span> Animation </span>
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/settings"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/settings.svg"></Image>{' '}
                <span> Settings </span>
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/categories"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/trends.svg"></Image>{' '}
                <span> Categories </span>
              </Nav.Link>
              {/* <Dropdown>
                <Dropdown.Toggle
                  variant="nav-link"
                  activeClassName="active"
                  as={NavLink}
                  to="/categories"
                  className="list-group-item list-group-item-action"
                  id="dropdown-basic"
                >
                  <Image src="./assets/icons/trends.svg"></Image>{" "}
                  <span> Categories </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              {/* <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/locations"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/locations.svg"></Image>{" "}
                <span> Locations </span>
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/product"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/product.svg"></Image>{" "}
                <span> Product </span>
              </Nav.Link> */}
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/preferences"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/preferences.svg"></Image>
                <span> Preferences </span>
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/configuration"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/configuration.svg"></Image>
                <span> Configuration </span>
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/commands"
                className="list-group-item list-group-item-action"
              >
                <Image src="./assets/icons/commands.svg"></Image>
                <span> Commands </span>
              </Nav.Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LayoutSidebar;
