import axios from 'axios';
import { api as API_URL } from './api';

class SensorsService {
  index(device, parent = null) {
    return axios
      .get(`${API_URL}sensors/${device.mac}`, {
        parent: parent,
      })
      .then((response) => {
        console.warn('sensors service index', response.data);

        return {
          status: 1,
          unsensors: response.data.unsensors,
          sensors: response.data.sensors,
        };
      })
      .catch((error) => {
        // handle error
        console.log(error);
        return { status: 0 };
      });
  }
}

export default new SensorsService();
