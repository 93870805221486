import React from "react";
import axios from "axios";

import "./style.css";

import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";

class Animation extends React.Component {
  render() {
    return (
      <Container fluid className="container-main mt-4">
        <h3 className="container-header"> 9th Street </h3>
        <Container fluid className="no-padding group"></Container>
      </Container>
    );
  }
}

export default Animation;
