import React from 'react';
import DevicesService from '../../../Services/devicesService';

import './style.css';

import { Container, Form, Button } from 'react-bootstrap';

class CreateDevice extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      error: false,
      redirect: false,
      name: '',
      fname: '',
      lname: '',
      mac: '',
      email: '',
      street: '',
      zipcode: '',
      country: '',
      latitude: '',
      longitude: '',
      blurb: '',
      visualization: '',
      url: '',
      video: '',
      twitterName: '',
      twitterNotify: '',
      consumerKey: '',
      consumerSecret: '',
      accessKey: '',
      accessSecret: '',
      shortUrl: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.addDevice = this.addDevice.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addDevice() {
    if (this.state.name !== '' && this.state.mac !== '') {
      let details = {
        name: this.state.name,
        fname: this.state.fname,
        lname: this.state.lname,
        mac: this.state.mac,
        email: this.state.email,
        street: this.state.street,
        zipcode: this.state.zipcode,
        country: this.state.country,
        latitude: this.state.latitude,
        blurb: this.state.blurb,
        visualization: this.state.visualization,
        url: this.state.url,
        video: this.state.video,
        twitterName: this.state.twitterName,
        twitterNotify: this.state.twitterNotify,
        consumerKey: this.state.consumerKey,
        consumerSecret: this.state.consumerSecret,
        accessKey: this.state.accessKey,
        accessSecret: this.state.accessSecret,
        shortUrl: this.state.shortUrl,
      };
      console.warn(details);
      DevicesService.create(details).then((response) => {
        if (response.status === 1) {
          window.location.reload(false);
        } else {
          console.log(response);
        }
      });
    } else {
      console.error('Please enter name and mac');
    }
  }

  render() {
    return (
      <Container fluid className="container-main mt-4">
        <Container fluid className="p-5 group">
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Name"
              onChange={this.handleChange}
              value={this.state.name}
            />
          </Form.Group>
          <Form.Group controlId="fname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="fname"
              placeholder="First name"
              onChange={this.handleChange}
              value={this.state.fname}
            />
          </Form.Group>
          <Form.Group controlId="lname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lname"
              placeholder="Last Name"
              onChange={this.handleChange}
              value={this.state.lname}
            />
          </Form.Group>
          <Form.Group controlId="mac">
            <Form.Label>Mac Addres</Form.Label>
            <Form.Control
              type="text"
              name="mac"
              placeholder="Mac Address"
              onChange={this.handleChange}
              value={this.state.mac}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Email"
              onChange={this.handleChange}
              value={this.state.email}
            />
          </Form.Group>
          <Form.Group controlId="street">
            <Form.Label>Street</Form.Label>
            <Form.Control
              type="text"
              name="street"
              placeholder="Street"
              onChange={this.handleChange}
              value={this.state.street}
            />
          </Form.Group>
          <Form.Group controlId="zipcode">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="number"
              name="zipcode"
              placeholder="Zip Code"
              onChange={this.handleChange}
              value={this.state.zipcode}
            />
          </Form.Group>
          <Form.Group controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="number"
              name="country"
              placeholder="Country"
              onChange={this.handleChange}
              value={this.state.country}
            />
          </Form.Group>
          <Form.Group controlId="latitude">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
              type="number"
              name="latitude"
              placeholder="Latitude"
              onChange={this.handleChange}
              value={this.state.latitude}
            />
          </Form.Group>
          <Form.Group controlId="longitude">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
              type="number"
              name="longitude"
              placeholder="Longitude"
              onChange={this.handleChange}
              value={this.state.longitude}
            />
          </Form.Group>
          <Form.Group controlId="blurb">
            <Form.Label>Blurb</Form.Label>
            <Form.Control
              type="text"
              name="blurb"
              placeholder="Blurb"
              onChange={this.handleChange}
              value={this.state.blurb}
            />
          </Form.Group>
          <Form.Group controlId="visualization">
            <Form.Label>Visualization</Form.Label>
            <Form.Control
              type="text"
              name="visualization"
              placeholder="Visualization"
              onChange={this.handleChange}
              value={this.state.visualization}
            />
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label>Url</Form.Label>
            <Form.Control
              type="text"
              name="url"
              placeholder="Url"
              onChange={this.handleChange}
              value={this.state.url}
            />
          </Form.Group>
          <Form.Group controlId="video">
            <Form.Label>Video</Form.Label>
            <Form.Control
              type="text"
              name="video"
              placeholder="Video"
              onChange={this.handleChange}
              value={this.state.video}
            />
          </Form.Group>
          <Form.Group controlId="twitterName">
            <Form.Label>Twitter Name</Form.Label>
            <Form.Control
              type="text"
              name="twitterName"
              placeholder="Twitter Name"
              onChange={this.handleChange}
              value={this.state.twitterName}
            />
          </Form.Group>
          <Form.Group controlId="twitterNotify">
            <Form.Label>Twitter Notify</Form.Label>
            <Form.Control
              type="text"
              name="twitterNotify"
              placeholder="Twitter Notify"
              onChange={this.handleChange}
              value={this.state.twitterNotify}
            />
          </Form.Group>
          <Form.Group controlId="consumerKey">
            <Form.Label>Consumer Key</Form.Label>
            <Form.Control
              type="text"
              name="consumerKey"
              placeholder="Consumer Key"
              onChange={this.handleChange}
              value={this.state.consumerKey}
            />
          </Form.Group>
          <Form.Group controlId="consumerSecret">
            <Form.Label>Consumer Secret</Form.Label>
            <Form.Control
              type="text"
              name="consumerSecret"
              placeholder="Consumer Secret"
              onChange={this.handleChange}
              value={this.state.consumerSecret}
            />
          </Form.Group>
          <Form.Group controlId="accessKey">
            <Form.Label>Access Key</Form.Label>
            <Form.Control
              type="text"
              name="accessKey"
              placeholder="Access Key"
              onChange={this.handleChange}
              value={this.state.accessKey}
            />
          </Form.Group>
          <Form.Group controlId="accessSecret">
            <Form.Label>Access Secret</Form.Label>
            <Form.Control
              type="text"
              name="accessSecret"
              placeholder="Access Secret"
              onChange={this.handleChange}
              value={this.state.accessSecret}
            />
          </Form.Group>
          <Form.Group controlId="shortUrl">
            <Form.Label>Short Url</Form.Label>
            <Form.Control
              type="text"
              name="shortUrl"
              placeholder="Short Url"
              onChange={this.handleChange}
              value={this.state.shortUrl}
            />
          </Form.Group>
          <div className="text-right">
            <Button
              onClick={() => this.addDevice()}
              className="btn btn-primary"
            >
              Submit
            </Button>
          </div>
        </Container>
      </Container>
    );
  }
}

export default CreateDevice;
