import React from 'react';

import AuthService from '../../../Services/authService';

import { Link, withRouter } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, Button, Image } from 'react-bootstrap';

class LayoutNavbar extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {}

  render() {
    let allDevices = this.props.devices.map((device) => {
      return (
        <option key={device.id} value={device.mac}>
          {device.name}
        </option>
      );
    });

    return (
      <div>
        <Navbar className="layoutNavbar" expand="lg">
          <h6> Home / {window.location.href}</h6>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ml-auto mt-2 mt-lg-0 form-select">
              <Form.Control
                value={this.props.selectedDevice.mac}
                as="select"
                onChange={this.props.deviceSelect}
              >
                <option disabled value="">
                  Select Device
                </option>
                {allDevices}
              </Form.Control>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ml-auto mt-2 mt-lg-0">
              <Nav.Link as={Link} to="/">
                <Image src="./assets/icons/store.svg"></Image>
              </Nav.Link>
              <NavDropdown title="User" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.props.logout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/">
                <Image src="./assets/icons/question.svg"></Image>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default LayoutNavbar;
